
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































.cta-mood {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: $spacing;
  background: $c-mustard;

  @include mq(m) {
    padding-bottom: $spacing * 2;
  }

  @include mq(l) {
    padding-bottom: 0;
  }
}

.cta-mood__content-outer {
  @include mq(l) {
    @include center-x;

    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.cta-mood__content {
  position: relative;
  z-index: 1;
  margin-top: $spacing * -1;
  padding: $spacing;
  background: rgba($c-white, 0.85);
  border-radius: 0.6rem;

  ::v-deep em {
    color: $c-dark-grey;
  }

  @include mq(m) {
    margin-top: $spacing * -2;
    padding: $spacing * 2;
  }

  @include mq(l) {
    z-index: 2;
    margin-top: 0;
    margin-right: col(6);
  }

  @include mq(wrapper) {
    padding: $spacing * 3;
  }
}

.cta-mood__content__link {
  &::after {
    @include get-all-space;

    content: '';
  }
}

.cta-mood__picture {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;

  img {
    @include image-fit;
  }

  @include mq(l) {
    width: col(7);
    aspect-ratio: 4/3;
    margin-left: auto;
  }

  @include mq(wrapper) {
    aspect-ratio: 3/2;
  }
}
